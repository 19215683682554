import React, { useState, useRef, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'

import Image from '../components/image'
import Icon from './icon'
import {Figcaption} from '../utils/styles'
import Container from '../components/container'

import theme from '../utils/theme';

const VideoPlayer = styled('figure')`
  margin: 0;
`

const AspectBox = styled('div')`
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%
`

const AspectBoxInner = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Stage = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000000;
`

const ClickCatcher = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${props =>
    props.idle ? 'none' : 'initial'};
`

const LargePlayButton = styled('div')`
  /*
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 45px solid white;
  border-bottom: 25px solid transparent;
  */
  cursor: pointer;
  /*
  display: ${props =>
    props.playing ? 'none' : 'block'};
  */
  display: flex;
  transition: opacity 0.15s ease-in-out;

  opacity: ${props =>
    props.idle && props.playing ? '0' : '1'};

  svg {
    width: 45px;
    height: 45px;
  }

  @media (hover:hover) {
    &:hover {
      opacity: 0.8;
    }
  }

  @media ${theme.breakpoints.md} {
    svg {
      width: 104px;
      height: 104px;
    } 
    /*
    border-top: 50px solid transparent;
    border-left: 90px solid white;
    border-bottom: 50px solid transparent;
    */
  }
`


const Controls = styled('div')`
  position: absolute;
  right: 20px;
  bottom: 15px;
  left: 20px;

  display: flex;

  z-index: 3;

  /*
  opacity: ${props =>
    props.initialized ? '1' : '0'};
  */
  /*
  transform: ${props =>
    props.initialized ? 'translateY(0)' : 'translateY(100%)'};
  */
  pointer-events: ${props =>
    props.initialized ? 'auto' : 'none'};
  transition: opacity 0.15s ease-in-out, transform 0.185s ease-in-out;

  ${props => {
    if (props.idle && props.playing) {
      return `
        opacity: 0;
        transform: translateY(100%);
    `
    } else if (props.initialized) {
      return `
        opacity: 1;
        transform: translateY(0);
    `
    } else {
      return `
        opacity: 0;
        transform: translateY(100%);
    `
    }
  }}

  /*
  opacity: ${props =>
    props.idle && props.playing ? '0' : '1'};
  */

  @media ${theme.breakpoints.md} {
    right: 35px;
    bottom: 35px;
    left: 35px;
  }
`

const Seekbar = styled('input')`
  width: 100%;
  background-color: transparent;
  appearance: none;
  // --webkitProgressPercent: 0%;

  &:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  &::-webkit-slider-thumb {
    appearance: none;
    height: 13px;
    width: 13px;
    border-radius: 16px;
    background: #ffffff;
    cursor: pointer;
    margin-top: -6px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    border: 0;
    box-shadow: 0 0 10px 0px rgb(0, 0, 0, 0.15);
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px 0px rgb(0, 0, 0, 0.15);
  }

  @media ${theme.breakpoints.md} {
    &::-webkit-slider-thumb {
      width: 15px;
      height: 15px;
      margin-top: -7px;
    }
  }
`

const FullscreenToggle = styled('button')`
  background-color: transparent;

  appearance: none;
  outline: none;
  border: 0;

  display: flex;

  margin-left: 20px;
  padding: 0;

  cursor: pointer;
`

const PlayerWrapper = styled('div')`
  width: 100%;
  height: 100%;
  opacity: 0.925;
`

const Poster = styled('div')`
  display: ${props =>
    props.initialized ? 'none' : 'block'};

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  > div {
    position: absolute!important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .gatsby-image-wrapper img {
    transition: opacity .5s ease 0s!important;
  }
`

/*
const Caption = styled('figcaption')`
  font-family: Plantin;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: 10px 0;
  position: absolute;
`
*/


function VideoPlayerComponent({vimeoID, posterImage, caption}) {
  const [initialized, setInitialized] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [played, setPlayed] = useState(0)
  const [seeking, setSeeking] = useState(false)
  const [idle, setIdle] = useState(false)

  const stage = useRef(null)
  const player = useRef(null)
  let idleTimeout;

  const setTimeouts = () => {
    idleTimeout = setTimeout(hideControls, 2000);
  }

  const clearTimeouts = () => {
    if (idleTimeout) clearTimeout(idleTimeout);
  }

  const resetTimeout = () => {
    clearTimeouts();
    setTimeouts();
    setIdle(false)
  }

  const hideControls = useCallback((event) => {
    //console.log('hide controls')
    if(!idle) setIdle(true)
  }, [])

  /*
  const checkIfIdle = useCallback((event) => {
    let timer
    clearTimeout(timer)
    setIdle(false)

    timer = setTimeout(hideControls, 2000);
  }, [])
  */

  const addEventListeners = () => {
    stage.current.addEventListener('mouseleave', hideControls)
    stage.current.addEventListener('mousemove', resetTimeout)
  }

  const removeEventListeners = () => {
    stage.current.removeEventListener('mouseleave', hideControls)
    stage.current.removeEventListener('mousemove', resetTimeout)
  }

  const handlePlayPause = () => {
    setPlaying(!playing)

    resetTimeout()

    playing ? removeEventListeners() : addEventListeners()
  }

  const onStart = () => {
    setInitialized(true)
  }

  const onEnded = () => {
    removeEventListeners()
  }

  const handleSeekMouseDown = e => {
    //this.setState({ seeking: true })
    //console.log('down')
    setSeeking(true)
  }

  const handleSeekChange = e => {
    // this.setState({ played: parseFloat(e.target.value) })
    setPlayed(parseFloat(e.target.value))
  }

  const handleSeekMouseUp = e => {
    //this.setState({ seeking: false })
    //console.log('up')
    setSeeking(false)
    player.current.seekTo(parseFloat(e.target.value))
  }

  const handleProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setPlayed(state.played)
    }
  }

  const handleClickFullscreen = () => {
    screenfull.toggle(stage.current)
    //console.log(stage.current);
    //console.log(player.current);

    //player.current.webkitEnterFullscreen();
  }

  /*
  const handlePlay = () => {
    console.log('onPlay')
    this.setState({ playing: true })
  }

  const handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }
  */



  return (
    <Container>
      <VideoPlayer>
        <AspectBox>
          <AspectBoxInner>
            <Stage ref={stage}>
              <ClickCatcher 
                onClick={handlePlayPause}
                idle={idle}
              >
                <LargePlayButton 
                  idle={idle}
                  playing={playing}
                >
                  { playing ?
                    <svg viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M42.3 104h-26V0h26v104zM87.7 0h-26v104h26V0z" fill="white"/>
                    </svg>
                    :
                    <svg viewBox="0 0 90 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M90 52L-4.89399e-06 103.962L-3.51373e-07 0.0384711L90 52Z" fill="white"/>
                    </svg>
                  }
                </LargePlayButton>
              </ClickCatcher>
              <Controls
                initialized={initialized}
                idle={idle}
                playing={playing}
              >
                <Seekbar
                  type='range' min={0} max={0.999999} step='any'
                  value={played}
                  onMouseDown={handleSeekMouseDown}
                  onChange={handleSeekChange}
                  onMouseUp={handleSeekMouseUp}
                />
                <FullscreenToggle onClick={handleClickFullscreen}>
                  <Icon symbol='fullscreen' />
                </FullscreenToggle>
              </Controls>
              <PlayerWrapper>
                <ReactPlayer 
                  ref={player}
                  //playsinline
                  url={`https://vimeo.com/${vimeoID}`}
                  className='react-player'
                  width='100%'
                  height='100%'
                  progressInterval={25}
                  playing={playing}
                  onStart={onStart}
                  onProgress={handleProgress}
                  onEnded={onEnded}
                />
                { posterImage &&
                  <Poster
                    initialized={initialized}
                  >
                    <Image filename={posterImage} />
                  </Poster>
                }
              </PlayerWrapper>
            </Stage>
          </AspectBoxInner>
        </AspectBox>
        { caption !== undefined &&
          <Figcaption>
            {caption}
          </Figcaption>
        }
      </VideoPlayer>
    </Container>
  );
}

export default VideoPlayerComponent